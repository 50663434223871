import React from 'react';
import {useAuth} from "../../../pages/UserCore/Auth/AuthContext";
import {generateTrainerImageUrl} from "../../../helpers/helpers";
import {useNavigate} from "react-router-dom";
import base_premium_icon from "../../../assets/icons/base_premium_icon.png";
import {usePremium} from "../../../pages/Premium/PremiumContext";
import $ from "jquery";


const HeaderUserBlock = () => {
    const navigate = useNavigate();
    const { user } = useAuth();
    const { statusPremium } = usePremium();

    const handleLinkSelfTrainer = (e) => {
        e.preventDefault()

        if (user){
            if (window.location.pathname.indexOf("/trainer/") >= 0 && window.location.pathname !== '/trainer/'+user.id && !$('.single_page_body')[0]){
                window.location = '/trainer/'+user.id
            }else{
                // временно
                $('.pokemon_one_body_content_inner_hystory_battle_link_button.down').click()

                navigate('/trainer/'+user.id);
            }
        }

        return false;
    };

    return (
        <a className="header_card_trainer style_1_interactive_button_link" href="/" onClick={handleLinkSelfTrainer}>
            {statusPremium ? <div className="header_mobile_list_one_link_profile_premium_text style_1_caption_12_500">
                Premium
            </div> : ''}
            <div className="header_card_trainer_id">
                <div className="header_card_trainer_id_title">ID</div>
                <div className="header_card_trainer_id_num">{user?.id ? user.id : ''}</div>
            </div>
            <div className="header_card_trainer_img">
                <img className="k_main_photo" src={generateTrainerImageUrl(user?.avatar_id ? user.avatar_id : '')} alt="логотип" />
            </div>
        </a>
    );
};

export default HeaderUserBlock;