import React from 'react';
import PropTypes from 'prop-types';
import {Link, useNavigate} from 'react-router-dom';

import './BackButton.css';
import base_arrow_left from "../../../assets/icons/base_arrow_left.svg";
import Cookies from "js-cookie";

const BackButton = ({link = false, link_validate = false}) => {
    const navigate = useNavigate();

    if (link_validate){
        link = link_validate;
    }

    const handleLinkBack = (e) => {
        if (Cookies.get('back_button_reset') === 'yes'){
            navigate('/trainers/', {replace: true});
            Cookies.set('back_button_reset', 'no', {expires: 365});
            return true;
        }

        if (link_validate){
            navigate(link_validate, {replace: false});
            Cookies.set('back_button_reset', 'yes', {expires: 365});
            return true;
        }

        if (!link){
            navigate(-1)
        }else{
            navigate(link, {replace: false})
        }
    };

    return (
        <Link to={link} className="top_back_button" onClick={handleLinkBack}>
            <img className="top_back_button_icon" src={base_arrow_left} alt="вернуться назад" />
            <span className="top_back_button_text style_1_caption_16_400">Назад</span>
        </Link>
    );
};

BackButton.propTypes = {
    link: PropTypes.string,
    link_validate: PropTypes.string
};

export default BackButton;