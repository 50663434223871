import React from 'react';
import {
    Box,
    FormHelperText,
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    IconButton
} from '@mui/material';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import './Auth.css';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { useAuth } from "./AuthContext";
import Gradient from "../../../components/Layout/Gradient/Gradient";
import ClassicTitle from "../../../components/PopupsWhite/ClassicTitle/ClassicTitle";
import InputStandart from "../../../components/PopupsWhite/InputStandart/InputStandart";
import LinkStandart from "../../../components/PopupsWhite/LinkStandart/LinkStandart";
import SendButtonStandart from "../../../components/PopupsWhite/SendButtonStandart/SendButtonStandart";
import SocialAuth from "../../../components/PopupsWhite/SocialAuth/SocialAuth";
import {kGetUrl} from "../../../helpers/helpers";
import {useOptions} from "../../../components/Options/OptionsContext";

import $ from "jquery";

const Auth = ({no_hidden = false}) => {
    const { login } = useAuth();
    const { optionsArr } = useOptions();
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const onAnimationStart = (e) => {
        if (e.animationName === 'mui-auto-fill'){
            $('#k_email').parent().parent().addClass('fixed_autofull')
            $('#k_password').parent().parent().addClass('fixed_autofull')
        }
    };

    $('body').off('keyup', '#k_email, #k_password');
    $('body').on('keyup', '#k_email, #k_password', function (){
        $('#k_email').parent().parent().removeClass('fixed_autofull')
        $('#k_password').parent().parent().removeClass('fixed_autofull')
    });


    return (
        <>
            <div className="auth" style={!no_hidden ? {'display': "none"} : {}}>
                <Gradient type="gradient_bottom" />
                <section className="style_1_popup_white_out style_1_popup_white_out_auth">
                    <div className="auth_mobile_top k_mobile_visible">
                        <h2 className="auth_mobile_top_title style_1_classic_26_400">Битва покемонов</h2>
                        <p className="auth_mobile_top_sub style_1_caption_14_500">QA Studio v{optionsArr.k_version ? optionsArr.k_version : ''}</p>
                    </div>

                    <div className="style_1_popup_white_in style_1_popup_white_in_auth">
                        <ClassicTitle title="Битва покемонов" />
                        <SocialAuth title="Войти с помощью" />

                        <Formik
                            initialValues={{
                                email: '',
                                password: ''
                            }}
                            validationSchema={Yup.object().shape({
                                email: Yup.string().matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[a-zA-Z]{2,}$/i, 'Введите корректную почту').matches(/^\S+$/i, 'Введите корректную почту').max(255).required('Введите почту'),
                                password: Yup.string().max(255).required('Введите пароль')
                            })}
                            onSubmit={async (values, {setErrors}) => {
                                await login(values, {setErrors});
                            }}
                        >
                            {({ errors, handleBlur, handleChange, isSubmitting, touched, values }) => (
                                <Form className={errors.submit ? 'style_1_all_error' : ''}>
                                    <InputStandart label="Почта" name="email" className='k_form_f_email' onBlur={handleBlur} onChange={handleChange} onAnimationStart={onAnimationStart} touched={touched.email} error={errors.email} value={values.email} />

                                    <FormControl className='k_form_control k_form_control_no_margin_bottom' fullWidth error={Boolean(touched.password && errors.password)}>
                                        <InputLabel htmlFor="k_password">Пароль</InputLabel>
                                        <OutlinedInput
                                            id="k_password"
                                            className="k_form_f_pass"
                                            type={showPassword ? 'text' : 'password'}
                                            value={values.password}
                                            name="password"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            label="Пароль"
                                            inputProps={{'className': 'k_form_f_pass'}}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge={"end" + (showPassword ? ' active' : '')}
                                                        size="large"
                                                        style={values.password.length > 0 ? {'display': 'block'} : {'display': 'none'}}
                                                    >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                        {touched.password && errors.password && (
                                            <FormHelperText error className="auth__error k_main_error_text" id="standard-weight-helper-text">
                                                {' '}
                                                {errors.password}{' '}
                                            </FormHelperText>
                                        )}
                                    </FormControl>

                                    {errors.submit && (
                                        <Box mt={3} className='k_error_submit'>
                                            <FormHelperText className="auth__error k_main_error_text" error>{errors.submit}</FormHelperText>
                                        </Box>
                                    )}

                                    <LinkStandart link="/change_password" title="Восстановить" type="style_1_popup_white_link_left"/>
                                    <SendButtonStandart title="Войти" className={'k_form_send_auth'} is_disabled={isSubmitting}/>

                                    <LinkStandart link="/registration" title="Зарегистрироваться" type="style_fix_1"/>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </section>
            </div>
        </>
    );
};

export default Auth;